<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>쪽지</agent-sub-title>
    <div class="content">
      <div class="main_panel">
        <div class="qa_item" v-for="(item, index) in list" :key="index">
          <div class="qa">
            <div class="qt">
              <span>[{{item.createTime|datef('MM월DD일 HH:mm')}}]</span>
              <el-button size="mini" type="info" @click="checkMessage(item)" style="margin-left: 5px" v-if="item.checked == agentConst.UNCHECKED">확인하기</el-button>
            </div>
            <div class="as">
              <div class="q" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>

</template>

<script>

import AgentSubTitle from "../../components/agent/AgentSubTitle";
import {agentMixin} from "@/common/agent/agentMixin";
import {
  getAgentMessageList, updateAgentMessageChecked,
} from "@/network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import Pagination from "@/components/pagenation/Pagination.vue";
import sportsConst from "@/common/sportsConst";

export default {
  name: "AgentMessage",
  components: {
    Pagination,
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      customercenter: {},
      list:[],
    }
  },
  methods: {
    getMessageList() {
      getAgentMessageList(this.pageNum, this.pageSize).then(res => {
        this.list = res.data.data;
      })
    },
    checkMessage(message){
      updateAgentMessageChecked(message.id).then(res=>{
        this.getMessageList()
      })
    }
  },
  created() {
    this.getMessageList()
  }
}
</script>

<style scoped>
.main_panel {
  width: 100%;
  background-color: #111111;
}
button{
  border: transparent;
}
.btn-save-question{
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(180deg, #74fcf8, #1ae0da);
  color: #353539;
}
.btn-askacno{
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(180deg, #0c9b97, #0c807c);
  color: #fff9ff;
}

.qa_item {
  width: 100%;
  background-color: #111111;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #616161;
}

.qa_item .qa {
  min-height: 100px;
}

.qa_item .qt {
  padding: 16px 0;
  color: #c1c1c1;
  font-weight: 600;
}

.qa_item .q {
  background-color: #4b4b4b;
  padding: 16px;
  color: #fff9ff;
  cursor: pointer;
}



.qa_item .btn-area {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 10px 0;
}
.qa_item .btn-area button{
  margin: 0 5px;
}


</style>